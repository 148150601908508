import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateAbout as onUpdateAbout,
  getAbout as onGetAbout,
} from "store/contacts/actions";
import axios from "axios";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

const EcommerceAddProduct = () => {
  document.title = "Bildirim | Mserviks Admin Paneli";

  const dispatch = useDispatch();

  const [getContactEdit, setContactEdit] = useState(null);

  const [getUsers, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [getRest, setRest] = useState([]);
  const [getSelectedRest, setSelectedRest] = useState([]);

  const [getIsActive, setIsActive] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
        },
    validationSchema: Yup.object({
      title: Yup.string().required("Lütfen Başlık Giriniz")
    }),
    onSubmit: (values) => {


      if(getIsActive == true){
        selectedUsers.map((item, index) => {

          const newUser = {
            title: values.title,
            body: values.desc,
            USERID:item,
            icerikTuru:"user"
          }
    
          axios.post('https://getjob.stechomeyazilim.info:5101/pushNotificationMSE/send', newUser).then(res => {
        
          showToast(true)
          console.log("şlsmdflş",res.data)
        })
  
        })
      }else{

        
        getUsers.map((item, index) => {

          const newUser = {
            title: values.title,
            body: values.desc,
            USERID:item,
            icerikTuru:"user"
          }
    
          axios.post('https://getjob.stechomeyazilim.info:5101/pushNotificationMSE/send', newUser).then(res => {
        
       
          console.log("şlsmdflş",res.data)
        })
  

        })

        
        showToast(true)

        
      }
      
    
      


      //dispatch(onUpdateAbout(updateOrder));
      validation.resetForm();

    },
  });

  const handleSelectChangeUsers = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedUsers(selectedOptions); // Seçilen değerleri durum olarak güncelleyin
  }

  const handleSelectChangeRest = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedRest(selectedOptions); // Seçilen değerleri durum olarak güncelleyin
  }

  function showToast(type) {
    console.log("type123",type)
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Bildirim Gönderilmiştir"
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  const { users } = useSelector(state => ({
    users: state.contacts.about,
  }));

  const _getUsers= async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserMSE/select/`)
      .then((res) => {
        setUsers(res.data)
      })
  }



  useEffect(() => {
    if (users && !users.length) {
      _getUsers();
      dispatch(onGetAbout());

    }
  }, [dispatch, users]);

  useEffect(() => {
    if(users.length > 0){
      setContactEdit(users[0]);
    }
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);
  const [searchTerm, setSearchTerm] = useState('');
  const [getSearchfilteredRest, setSearchfilteredRest] = useState('');

  
  const handleInputChange = (event) => {
    console.log("slndlf",event.target.value)
      setSearchTerm(event.target.value);
  };

  const handleInputChangeRest = (event) => {
    console.log("slndlf",event.target.value)
    setSearchfilteredRest(event.target.value);
  };

  const filteredUsers = getUsers.filter(item => item.Users_NameSurname.includes(searchTerm))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Bildirim" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Bildirim</CardTitle>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>

                      <div className="mb-3">
                        <Label htmlFor="productname">Başlık</Label>
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          className="form-control"
                          placeholder="Başlık"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.title || ""}
                          invalid={
                            validation.touched.title && validation.errors.title ? true : false
                          }
                        />
                        {validation.touched.title && validation.errors.title ? (
                          <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                        ) : null}

                      </div>
                      

                      <div className="mb-3">
                        <Label htmlFor="productname">Açıklama</Label>
                        <Input
                          id="desc"
                          name="desc"
                          type="text"
                          className="form-control"
                          placeholder="Başlık"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.desc || ""}
                          invalid={
                            validation.touched.desc && validation.errors.desc ? true : false
                          }
                        />
                        {validation.touched.desc && validation.errors.desc ? (
                          <FormFeedback type="invalid">{validation.errors.desc}</FormFeedback>
                        ) : null}

                      </div>
                   
                      <div className="mb-3">

                      <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Üye"
                              data-off-label="Herkes"
                            />
                          </div>


                     {getIsActive == true ? 
                     <div className="mb-3">
                      <Form>
                                <Label for="search">Üye Adı:</Label>
                                <Input
                                    type="text"
                                    id="search"
                                    placeholder="Üye adını girin"
                                    value={searchTerm}
                                    onChange={handleInputChange}
                                />
                            </Form>

                            

                      <div className="mb-3">
                        <Label className="form-label">Kullanıcı Seçiniz</Label>
                        <Input
                          name="group"
                          type="select"
                          className="form-select"
                          multiple
                          value={selectedUsers} // Burada selectedValues, bileşenin seçili değerlerini içeren bir durumdur
                          onChange={(event) => handleSelectChangeUsers(event)}

                        >

{filteredUsers.length > 0 ? filteredUsers.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Users_NameSurname}</option>
                          )) : getUsers.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Users_NameSurname}</option>
                          ))}

                        </Input>
                       
                      </div>
                      </div>: null}

            

                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Gönder
                      </Button>
                      
                    </div>
                  </Form>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
