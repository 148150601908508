export const DELETE_BLOGCAT = "DELETE_BLOGCAT"
export const DELETE_BLOGCAT_SUCCESS = "DELETE_BLOGCAT_SUCCESS"

export const UPDATE_BLOGCAT = "UPDATE_BLOGCAT"
export const UPDATE_BLOGCAT_SUCCESS = "UPDATE_BLOGCAT_SUCCESS"

export const ADD_BLOGCAT  = "ADD_BLOGCAT"
export const ADD_BLOGCAT_SUCCESS = "ADD_BLOGCAT_SUCCESS"



export const GET_BLOGCAT = "GET_BLOGCAT"
export const GET_BLOGCAT_SUCCESS = "GET_BLOGCAT_SUCCESS"





export const DELETE_POOL = "DELETE_POOL"
export const DELETE_POOL_SUCCESS = "DELETE_POOL_SUCCESS"

export const UPDATE_POOL = "UPDATE_POOL"
export const UPDATE_POOL_SUCCESS = "UPDATE_POOL_SUCCESS"

export const ADD_POOL  = "ADD_POOL"
export const ADD_POOL_SUCCESS = "ADD_POOL_SUCCESS"


export const GET_POOL = "GET_POOL"
export const GET_POOL_SUCCESS = "GET_POOL_SUCCESS"

export const GET_USERSCOMPLATEPOOL = "GET_USERSCOMPLATEPOOL"
export const GET_USERSCOMPLATEPOOL_SUCCESS = "GET_USERSCOMPLATEPOOL_SUCCESS"

export const DELETE_POOL_ANSWER = "DELETE_POOL_ANSWER"
export const DELETE_POOL_ANSWER_SUCCESS = "DELETE_POOL_ANSWER_SUCCESS"

export const UPDATE_POOL_ANSWER = "UPDATE_POOL_ANSWER"
export const UPDATE_POOL_ANSWER_SUCCESS = "UPDATE_POOL_ANSWER_SUCCESS"

export const ADD_POOL_ANSWER  = "ADD_POOL_ANSWER"
export const ADD_POOL_ANSWER_SUCCESS = "ADD_POOL_ANSWER_SUCCESS"


export const GET_POOL_ANSWER = "GET_POOL_ANSWER"
export const GET_POOL_ANSWER_SUCCESS = "GET_POOL_ANSWER_SUCCESS"







export const DELETE_POOL_QUESTION = "DELETE_POOL_QUESTION"
export const DELETE_POOL_QUESTION_SUCCESS = "DELETE_POO_QUESTIONL_SUCCESS"

export const UPDATE_POOL_QUESTION = "UPDATE_POOL_QUESTION"
export const UPDATE_POOL_QUESTION_SUCCESS = "UPDATE_POOL_QUESTION_SUCCESS"

export const ADD_POOL_QUESTION  = "ADD_POOL_QUESTION"
export const ADD_POOL_QUESTION_SUCCESS = "ADD_POOL_QUESTION_SUCCESS"


export const GET_POOL_QUESTION = "GET_POOL_QUESTION"
export const GET_POOL_QUESTION_SUCCESS = "GET_POOL_QUESTION_SUCCESS"


export const DELETE_BLOGSUBCAT = "DELETE_BLOGSUBCAT"
export const DELETE_BLOGSUBCAT_SUCCESS = "DELETE_BLOGSUBCAT_SUCCESS"

export const UPDATE_BLOGSUBCAT = "UPDATE_BLOGSUBCAT"
export const UPDATE_BLOGSUBCAT_SUCCESS = "UPDATE_BLOGSUBCAT_SUCCESS"

export const ADD_BLOGSUBCAT  = "ADD_BLOGSUBCAT"
export const ADD_BLOGSUBCAT_SUCCESS = "ADD_BLOGSUBCAT_SUCCESS"



export const GET_BLOGSUBCAT = "GET_BLOGSUBCAT"
export const GET_BLOGSUBCAT_SUCCESS = "GET_BLOGSUBCAT_SUCCESS"





export const DELETE_SUBEDUCATION = "DELETE_SUBEDUCATION"
export const DELETE_SUBEDUCATION_SUCCESS = "DELETE_SUBEDUCATION_SUCCESS"

export const UPDATE_SUBEDUCATION = "UPDATE_SUBEDUCATION"
export const UPDATE_SUBEDUCATION_SUCCESS = "UPDATE_SUBEDUCATION_SUCCESS"

export const ADD_SUBEDUCATION = "ADD_SUBEDUCATION"
export const ADD_SUBEDUCATION_SUCCESS = "ADD_SUBEDUCATION_SUCCESS"



export const GET_SUBEDUCATION = "GET_SUBEDUCATION"
export const GET_SUBEDUCATION_SUCCESS = "GET_SUBEDUCATION_SUCCESS"

export const GET_ABOUT_SUCCESS = "GET_ABOUT_SUCCESS"

export const UPDATE_EDUCATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS"
export const UPDATE_EDUCATION = "UPDATE_EDUCATION"

export const ADD_EDUCATION = "ADD_EDUCATION"
export const ADD_EDUCATION_SUCCESS = "ADD_EDUCATION_SUCCESS"





export const GET_OFFER = "GET_OFFER"
export const GET_OFFER_SUCCESS = "GET_OFFER_SUCCESS"

export const DELETE_OFFER = "DELETE_OFFER"
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS"



export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS"
export const UPDATE_OFFER = "UPDATE_OFFER"

export const ADD_OFFER  = "ADD_OFFER"
export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS"



export const GET_ADVERT = "GET_ADVERT"
export const GET_ADVERT_SUCCESS = "GET_ADVERT_SUCCESS"

export const DELETE_ADVERT = "DELETE_ADVERT"
export const DELETE_ADVERT_SUCCESS = "DELETE_ADVERT_SUCCESS"



export const UPDATE_ADVERT_SUCCESS = "UPDATE_ADVERT_SUCCESS"
export const UPDATE_ADVERT = "UPDATE_ADVERT"

export const ADD_ADVERT  = "ADD_ADVERT"
export const ADD_ADVERT_SUCCESS = "ADD_ADVERT_SUCCESS"





export const GET_AVAILABLE = "GET_AVAILABLE"
export const GET_AVAILABLE_SUCCESS = "GET_AVAILABLE_SUCCESS"

export const DELETE_AVAILABLE = "DELETE_AVAILABLE"
export const DELETE_AVAILABLE_SUCCESS = "DELETE_AVAILABLE_SUCCESS"



export const UPDATE_AVAILABLE_SUCCESS = "UPDATE_AVAILABLE_SUCCESS"
export const UPDATE_AVAILABLE = "UPDATE_AVAILABLE"

export const ADD_AVAILABLE  = "ADD_AVAILABLE"
export const ADD_AVAILABLE_SUCCESS = "ADD_AVAILABLE_SUCCESS"



export const GET_RESERVATION = "GET_RESERVATION"
export const GET_RESERVATION_SUCCESS = "GET_RESERVATION_SUCCESS"

export const DELETE_RESERVATION = "DELETE_RESERVATION"
export const DELETE_RESERVATION_SUCCESS = "DELETE_RESERVATION_SUCCESS"



export const UPDATE_RESERVATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS"
export const UPDATE_RESERVATION  = "UPDATE_EDUCATION"

export const ADD_RESERVATION  = "ADD_EDUCATION"
export const ADD_RESERVATION_SUCCESS = "ADD_EDUCATION_SUCCESS"


export const GET_PRODUCTCATEGORY = "GET_PRODUCTCATEGORY"
export const GET_PRODUCTCATEGORY_SUCCESS = "GET_PRODUCTCATEGORY_SUCCESS"

export const GET_PRODUCT = "GET_PRODUCT"
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS"

export const GET_SLİDERS = "GET_SLİDERS"
export const GET_SLİDERS_SUCCESS = "GET_SLİDERS_SUCCESS"

export const GET_BLOGS = "GET_BLOGS"
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS"

export const GET_SSS = "GET_SSS"
export const GET_SSS_SUCCESS = "GET_SSS_SUCCESS"

export const DELETE_SSS = "DELETE_SSS"
export const DELETE_SSS_SUCCESS = "DELETE_SSS_SUCCESS"

export const GET_EDUCATION = "GET_EDUCATION"
export const GET_EDUCATION_SUCCESS = "GET_EDUCATION_SUCCESS"

export const DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS = "DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS"
export const DELETE_DYNAMICFORMEXTRAEXTRA = "DELETE_DYNAMICFORMEXTRAEXTRA"

export const DELETE_DYNAMICFORM = "DELETE_DYNAMICFORM"
export const DELETE_DYNAMICFORM_SUCCESS = "DELETE_DYNAMICFORM_SUCCESS"

export const DELETE_DYNAMICFORMEXTRA = "DELETE_DYNAMICFORMEXTRA"
export const DELETE_DYNAMICFORMEXTRA_SUCCESS = "DELETE_DYNAMICFORMEXTRA_SUCCESS"

export const DELETE_COURSESCHEDULES = "DELETE_COURSESCHEDULES"
export const DELETE_COURSESCHEDULES_SUCCESS = "DELETE_COURSESCHEDULES_SUCCESS"

export const ADD_GALLERYMULTIPLE = "ADD_GALLERYMULTIPLE"
export const ADD_GALLERYMULTIPLE_SUCCESS = "ADD_GALLERYMULTIPLE_SUCCESS"

export const DELETE_GALLERY = "DELETE_GALLERY"
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS"

export const ADD_GALLERY = "ADD_GALLERY"
export const ADD_GALLERY_SUCCESS = "ADD_GALLERY_SUCCESS"


export const ADD_PRODUCTCATEGORY = "ADD_PRODUCTCATEGORY"
export const ADD_PRODUCTCATEGORY_SUCCESS = "ADD_PRODUCTCATEGORY_SUCCESS"



export const ADD_SSS = "ADD_SSS"
export const ADD_SSS_SUCCESS = "ADD_SSS_SUCCESS"


export const ADD_BLOG= "ADD_BLOG"
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS"

export const DELETE_REFERANCES = "DELETE_REFERANCES"
export const DELETE_REFERANCES_SUCCESS = "DELETE_REFERANCES_SUCCESS"

export const DELETE_POPUP = "DELETE_POPUP"
export const DELETE_POPUP_SUCCESS = "DELETE_POPUP_SUCCESS"

export const DELETE_PRODUCTCATEGORY_SUCCESS = "DELETE_PRODUCTCATEGORY_SUCCESS"
export const DELETE_PRODUCTCATEGORY = "DELETE_PRODUCTCATEGORY"



export const ADD_PRODUCT= "ADD_PRODUCT"
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS"

export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"

export const UPDATE_SSS= "UPDATE_SSS"
export const UPDATE_SSS_SUCCESS = "UPDATE_SSS_SUCCESS"

export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT = "DELETE_PRODUCT"

export const DELETE_SLIDER_SUCCESS = "DELETE_SLIDER_SUCCESS"
export const DELETE_SLIDER = "DELETE_SLIDER"


export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS"
export const DELETE_BLOG = "DELETE_BLOG"

export const DELETE_NEWS = "DELETE_NEWS"
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS"
export const DELETE_MEGA_SUCCESS = "DELETE_MEGA_SUCCESS"
export const DELETE_MEGA = "DELETE_MEGA"
export const ADD_MEGA_SUCCESS = "ADD_MEGA_SUCCESS"

export const GET_DYNAMICFORM_EXTRA_EXTRA = "GET_DYNAMICFORM_EXTRA_EXTRA"
export const GET_DYNAMICFORMEXTRAEXTRA_SUCCESS = "GET_DYNAMICFORMEXTRAEXTRA_SUCCESS"
export const GET_DYNAMICFORMEXTRA_SUCCESS = "GET_DYNAMICFORMEXTRA_SUCCESS"
export const GET_DYNAMICFORM_EXTRA = "GET_DYNAMICFORM_EXTRA"
export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS"
export const GET_CLASSES = "GET_CLASSES"
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"


export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS"
export const GET_GALLERY = "GET_GALLERY"

export const UPDATE_WORKSHOP2_SUCCESS = "UPDATE_WORKSHOP2_SUCCESS"

export const UPDATE_SUBDOMAIN = "UPDATE_SUBDOMAIN"

export const UPDATE_NEWS = "UPDATE_NEWS"

export const UPDATE_PROJECT = "UPDATE_PROJECT"

export const UPDATE_DYNAMICFORM = "UPDATE_DYNAMICFORM"
export const GET_DYNAMICFORM_SUCCESS = "GET_DYNAMICFORM_SUCCESS"
export const UPDATE_DYNAMICFORM_SUCCESS = "UPDATE_DYNAMICFORM_SUCCESS"


export const ADD_DYNAMICFORM_SUCCESS = "ADD_DYNAMICFORM_SUCCESS"

export const UPDATE_SLIDER = "UPDATE_SLIDER"
export const UPDATE_SLIDER_SUCCESS = "UPDATE_SLIDER_SUCCESS"

export const UPDATE_SUCCESS_PROJECT = "UPDATE_SUCCESS_PROJECT"
export const UPDATE_SUCCESS_GALLERY = "UPDATE_SUCCESS_GALLERY"

export const UPDATE_COURSESCHEDULES = "UPDATE_COURSESCHEDULES"
export const UPDATE_SUBDOMAIN_SUCCESS = "UPDATE_SUBDOMAIN_SUCCESS"
export const UPDATE_WORKSHOPSESSION_SUCCESS = "UPDATE_WORKSHOPSESSION_SUCCESS"
export const UPDATE_SUCCESS_COURSESCHEDULES = "UPDATE_SUCCESS_COURSESCHEDULES"
export const UPDATE_SUCCESS_TEACHER = "UPDATE_SUCCESS_TEACHER"

export const UPDATE_SUCCESS_NEWS = "UPDATE_SUCCESS_NEWS"
export const UPDATE_SUCCESS_MEGA = "UPDATE_SUCCESS_MEGA"
export const UPDATE_MEGA = "UPDATE_MEGA"
export const UPDATE_WORKSHOP2 = "UPDATE_WORKSHOP2"
export const UPDATE_GALLERY = "UPDATE_GALLERY"
export const UPDATE_REFERANCES = "UPDATE_REFERANCES"
export const UPDATE_REFERANCES_SUCCESS = "UPDATE_REFERANCES_SUCCESS"

export const UPDATE_BLOG = "UPDATE_BLOG"
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS"
export const UPDATE_PRODUCTCATEGORY = "UPDATE_PRODUCTCATEGORY"
export const UPDATE_PRODUCTCATEGORY_SUCCESS = "UPDATE_PRODUCTCATEGORY_SUCCESS"
export const GET_TEACHER_WORKSHOP = "GET_TEACHER_WORKSHOP"
export const GET_WORKSHOP2_SUCCESS = "GET_WORKSHOP2_SUCCESS"
export const GET_SUBPROJECT_SUCCESS = "GET_SUBPROJECT_SUCCESS"

export const GET_AUTHORITY = "GET_AUTHORITY"
export const GET_CONTACT = "GET_CONTACT"
export const GET_ABOUT = "GET_ABOUT"
export const UPDATE_CONTACT = "UPDATE_CONTACT"
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS"
export const UPDATE_ABOUT = "UPDATE_ABOUT"
export const UPDATE_ABOUT_SUCCESS = "UPDATE_ABOUT_SUCCESS"

export const GET_AUTH_SUCCESS = "GET_AUTH_SUCCESS"
export const UPDATE_AUTH = "UPDATE_AUTH"
export const GET_WORKSHOP2 = "GET_WORKSHOP2"
export const GET_REFERANCES = "GET_REFERANCES"
export const GET_REFERANCES_SUCCESS = "GET_REFERANCES_SUCCESS"
export const ADD_REFERANCES = "ADD_REFERANCES"
export const ADD_REFERANCES_SUCCESS = "ADD_REFERANCES_SUCCESS"
export const ADD_DYNAMIC_FORM = "ADD_DYNAMIC_FORM"
export const ADD_SLIDER = "ADD_SLIDER"
export const ADD_SLIDER_SUCCESS = "ADD_SLIDER_SUCCESS"

export const GET_AUTHUPDATE_SUCCESS = "GET_AUTHUPDATE_SUCCESS"
export const GET_SUBPROJECT = "GET_SUBPROJECT"
export const GET_CLASSATTENDANCE = "GET_CLASSATTENDANCE"
export const GET_CLASSATTENDANCE_SUCCESS = "GET_CLASSATTENDANCE_SUCCESS"

export const UPDATE_LESSONS_TIME = "UPDATE_LESSONS_TIME"
export const ADD_LESSONSTIME_SUCCESS = "ADD_LESSONSTIME_SUCCESS"
export const ADD_LESSONS_TIME = "ADD_LESSONS_TIME"
export const ADD_WORKSHOP2 = "ADD_WORKSHOP2"
export const ADD_APPSETTING = "ADD_APPSETTING"
export const ADD_WORKSHOPSUBDOMAIN = "ADD_WORKSHOPSUBDOMAIN"
export const ADD_COURSE = "ADD_COURSE"
export const ADD_MEGA = "ADD_MEGA"
export const ADD_NEWS = "ADD_NEWS"
export const ADD_PROJECT = "ADD_PROJECT"

export const ADD_POPUP = "ADD_POPUP"
export const UPDATE_TEACHER = "UPDATE_TEACHER"
export const UPDATE_WORKSHOPSESSION = "UPDATE_WORKSHOPSESSION"
export const UPDATE_POPUP = "UPDATE_POPUP"
export const GET_POPUP_SUCCESS = "GET_POPUP_SUCCESS"
export const UPDATE_POPUP_SUCCESS = "UPDATE_POPUP_SUCCESS"
export const GET_TEACHERWORKSHOP_SUCCESS = "GET_TEACHERWORKSHOP_SUCCESS"
export const UPDATE_LESSONSTIME_SUCCESS = "UPDATE_LESSONSTIME_SUCCESS"

export const GET_WORKSHOP_SUCCESS = "GET_WORKSHOP_SUCCESS"
export const GET_WORKSHOPSESSION_SUCCESS = "GET_WORKSHOPSESSION_SUCCESS"
export const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS"
export const GET_SLIDER_SUCCESS = "GET_SLIDER_SUCCESS"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS"
export const GET_MEGA_SUCCESS = "GET_MEGA_SUCCESS"
export const GET_TEACHER_SUCCESS = "GET_TEACHER_SUCCESS"
export const GET_DYNAMIC_SUCCESS = "GET_DYNAMIC_SUCCESS"

/* POPUP */
export const GET_POPUP = "GET_POPUP"
export const GET_WORKSHOP = "GET_WORKSHOP"
export const GET_CourseSchedulesMega = "GET_CourseSchedulesMega"
export const GET_WorkshopSessionMega = "GET_WorkshopSessionMega"
export const GET_TeacherMega = "GET_TeacherMega"
export const GET_EVENT = "GET_EVENT"
export const GET_DYNAMICFORM = "GET_DYNAMICFORM"
export const GET_SLIDER = "GET_SLIDER"
export const GET_PROJECTSNEW = "GET_PROJECTSNEW"
export const GET_NEWS = "GET_NEWS"
export const GET_MEGA = "GET_MEGA"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"


/**
 * add user
 */
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"
