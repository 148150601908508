import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname } from "./contactlistCol";
import DateModal from "components/Common/DateModal";
import {ExportToExcel2} from '../../../ExportToExcel2'


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

import {
  getUsersComplatePool as onGetPool,
  addPool as onAddPool,
  updatePool as onUpdatePool,
  deletePool as onDeletePool,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "TAMAMLANAN ANKETLER| MSERVİKS ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();

  const [getFileSubMedia, setFileSubMedia] = useState([]);
  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);



  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {

    },
    validationSchema: Yup.object({


    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {


        };
        dispatch(onUpdatePool(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
   

        };
        // save new user
        dispatch(onAddPool(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.userscomplatepool,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);


  const [getCategoryID, setCategoryID] = useState(null);


  const [getCategory, setCategory] = useState([]);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Anket Soru",
        accessor: "Pool_Answer.Pool_Question.Pool_Question_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Anket Cevap",
        accessor: "Pool_Answer.Pool_Answer_Text",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Kullanıcı",
        accessor: "Users.Users_NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Tarih",
        accessor: "CreatedDateTime",
        filterable: true,
        Cell: cellProps => {
          const date = new Date(cellProps.value);
          const formattedDate = date.toLocaleDateString("tr-TR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
     
         
          });
          return <span>{formattedDate}</span>;
        },
      }
   
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductsCategoryLuxun/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()
    if (users && !users.length) {
      dispatch(onGetPool());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };



  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);
  const [getSelectDate, setSelectDate] = useState(null);
  const [getSelectDateFinish, setSelectDateFinish] = useState(null);

  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }


  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeletePool(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }



  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }

  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response)
          setFileSubMediaAdd(oldArray => [{ fileName: result.file.filename, fileType: result.file.mimetype }, ...oldArray]);
        }
      }
    }
  }

  const [getExcelModal, setExcelModal] = useState(false);

    
  const exportToCSV = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = getSelectDate
    let getSelectDateDaysFinish = moment(getSelectDateFinish).add(1, 'days').format('YYYY/MM/DD')

  const data2 =  users.filter(x=> x.CreatedDateTime > getSelectDate && x.CreatedDateTime < getSelectDateDaysFinish).map(elt=> [
    elt.Users.Users_NameSurname,
    elt.Pool_Answer.Pool_Answer_Text,
    elt.Pool_Answer.Pool_Question.Pool_Question_Title,
      ]);

    const ws = XLSX.utils.json_to_sheet(data2);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }
  const [getDateClick, setDateClick] = useState(null);

  const onClickExcel = (order) => {
    setDateClick(order);
    setExcelModal(true);
  }

  return (
    <React.Fragment>

<DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}
        onFilterClick={exportToCSV}
        show={getExcelModal}
        onCloseClick={() => setExcelModal(false)}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Cevaplanan Anketler Listesi" />
          <ExportToExcel2 title={"Tarih Excel"} onDownloadClick={exportToCSV} onClickExcel={onClickExcel} apiData={users} fileName={"fileName"} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddPool={false}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

          
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
