import React, { useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const Line = ({dataColors,reservation}) => {
  const lineEChartColors = getChartColorsArray(dataColors);

  const monthlyTotals = useMemo(() => {
    const totals = Array(12).fill(0); // 12 ay için başlangıç değerleri 0
    reservation.forEach(order => {
      const date = new Date(order.Created_DateTime);
      const month = date.getMonth(); // Ay indeksini al (0-11 arası)
      totals[month] += order.Time;
    });
    return totals;
  }, [reservation]);

  
  const options = {
    tooltip: {
      trigger: "axis",
    },
    grid: {
      zlevel: 0,
      x: 50,
      x2: 50,
      y: 30,
      y2: 30,
      borderWidth: 0,
    },
    xAxis: {
      type: "category",
      data: ["OCAK", "ŞUBAT", "MART", "NİSAN", "MAYIS", "HAZİRAN", "TEMMUZ","AĞUSTOS","EYLÜL","EKİM","KASIM","ARALIK"],
      axisLine: {
        lineStyle: {
          color: "#8791af",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#8791af"
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(166, 176, 207, 0.1)"
        }
      }
    },
    series: [
      {
        data: monthlyTotals,
        type: "line",
      },
    ],
    color: lineEChartColors,
    textStyle: {
      color: ["#8791af"],
    },
  };
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  );
};
export default Line;
