import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Status, Date } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getDynamicForm as onGetDynamicForm,
  addDynamicForm as onaddDynamicForm,
  updateDynamicForm as onUpdateDynamicForm,
  deleteDynamicForm as onDeleteDynamicForm,
  getReservation as onGetReservation,
  getUsers as onGetUsers,
} from "store/contacts/actions";


import { isEmpty } from "lodash";
import moment from "moment";
import Line from "../../AllCharts/echart/linechart"
import Doughnut from "../../AllCharts/echart/doughnutchart"
import {ExportToExcel2} from '../../../ExportToExcel2'
import DateModal from "components/Common/DateModal";

import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {

  //meta title
  document.title = "Grafikler | Mserviks B2B ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEdit, setEdit] = useState();

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (getEdit && getEdit.Dynamic_Form_Name) || "",
      startAge: (getEdit && getEdit.Dynamic_Form_StartAge) || "",
      finishAge: (getEdit && getEdit.Dynamic_Form_FinishAge) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Lütfen Adınızı Giriniz"),
      startAge: Yup.string().required("Başlangıç Yaşınızı Giriniz"),
      finishAge: Yup.string().required("Bitiş Yaşınızı Giriniz")
    }),
    onSubmit: values => {
      var localDate = moment().local();

      if (isEdit) {
        const updateUser = {
          ID: getEdit.ID,
          Dynamic_Form_Name:values.name,
          Dynamic_Form_StartAge: values.startAge,
          Dynamic_Form_FinishAge: values.finishAge,
          Dynamic_Form_IsMeram: getIsMeram,
          Dynamic_Form_IsActive: getActive
                };
                console.log("updateUser",updateUser)
        // update user
        dispatch(onUpdateDynamicForm(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Dynamic_Form_Name:values["name"],
          Dynamic_Form_StartAge: values["startAge"],
          Dynamic_Form_FinishAge: values["finishAge"],
          Dynamic_Form_IsMeram: getIsMeram,
          Dynamic_Form_IsActive: getActive,
          Dynamic_Form_CreatedDateTime: localDate
        };
        // save new user
        dispatch(onaddDynamicForm(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));


  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getEditForm, setEditForm] = useState(false);
  const [getActive, setActive] = useState(false);
  const [getIsMeram, setIsMeram] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Form Adı",
        accessor: "Dynamic_Form_Name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
    
      {
        Header: "Meramda Oturuyor Musun?",
        accessor: "Dynamic_Form_IsMeram",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
  
      {
        Header: "Durum",
        accessor: "Dynamic_Form_IsActive",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: 'Kayıt Tarihi',
        accessor: 'Dynamic_Form_CreatedDateTime',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
                <Link
              
                to="/addDynamicFormElement"
                className="text-danger"
                state={{ _item: cellProps.row.original }}

              >
              
                Element Ekle
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
      setIsEdit(false);
   }
  }, [dispatch, users]);

  useEffect(() => {
    if (reservation && !reservation.length) {
      dispatch(onGetReservation());
      setIsEdit(false);
   }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setEditForm(user)
    setContact(user);
    setIsEdit(true);

    setActive(user.Dynamic_Form_IsActive)
    setIsMeram(user.Dynamic_Form_IsMeram)

    setEdit(user)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteDynamicForm(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  const { reservation } = useSelector(state => ({
    reservation: state.contacts.reservation,
  }));

  const [getExcelModal, setExcelModal] = useState(false)

  const [getSelectDateFinish, setSelectDateFinish] = useState(null);
  const [getSelectDate, setSelectDate] = useState(null);

  const onOpen = (order) => {
    
    setExcelModal(true);
  }

  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }

    const filterFunc = (text) => {

    }

    
    const [count, setCount] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setCount(prevCount => {
          if (prevCount < 100) {
            return prevCount + 1;
          } else {
            clearInterval(interval);
            return prevCount;
          }
        });
      }, 100); // Zaman aralığını 100ms olarak ayarladık, bu daha yavaş bir animasyon sağlar.
  
      return () => clearInterval(interval);
    }, []);

  return (
    <React.Fragment>


<DateModal
       
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}
        onFilterClick={filterFunc}
        show={getExcelModal}
        onCloseClick={() => setExcelModal(false)}
      />
      

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />



      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Grafikler" />
        
{/**  <ExportToExcel2 onClickExcel={onOpen} title={"Tarihe Göre"}  apiData={reservation} fileName={"fileName"} /> */}
        

          <Row>
          <Col xl="6">
          <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                            Toplam Kayıt
                            </p>
                            <AnimatedNumber value={reservation.length} />
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                 "bx-purchase-tag-alt"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    
                    </Col>

             

          </Row>

          <Row>
          <Col xl="12">
         
          <div id="line-chart" className="e-chart">
                    <Line reservation={reservation} dataColors='["--bs-success"]'/>
                  </div>

                
                  
                  </Col>

                
                  </Row>


                  <Row>
          <Col xl="12">
         
          <div id="doughnut-chart" className="e-chart">
                    <Doughnut  reservation={reservation} dataColors='["--bs-primary","--bs-warning", "--bs-danger","--bs-info", "--bs-success"]'/>
                  </div>
                
                  
                  </Col>

                
                  </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

function AnimatedNumber({ value }) {
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (displayValue < value) {
        setDisplayValue(prevDisplayValue => prevDisplayValue + 1);
      }
    }, 20); // Artış hızını 20ms olarak ayarladık, bu daha hızlı bir animasyon sağlar.

    return () => clearInterval(interval);
  }, [value, displayValue]);

  return  <h4 className="mb-0">{displayValue}</h4>
}


export default withRouter(ContactsList);
